import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import "./assets/index.postcss";
import "@jamescoyle/svg-icon";
import axios from "axios";
import Keycloak from "keycloak-js";
import { createPinia } from "pinia";
import router from "./router";
import App from "./App.vue";

const app = createApp(App);
app.config.globalProperties.$filters = {
  formatDate(value: string) {
    return new Date(value).toLocaleDateString("fr-FR");
  },
  capitalize(value: string) {
    const firstLetter = value.charAt(0);
    const lowerCase = value.toLowerCase();
    const leftOver = lowerCase.slice(1);
    return firstLetter.toUpperCase() + leftOver;
  },
};

function authKeycloak() {

  const pinia = createPinia();

  const initOptions = {
    url: import.meta.env.VITE_APP_SSO_URL as string,
    realm: "azfalte",
    clientId: import.meta.env.VITE_APP_CLIENT_ID as string,
  };

  const keycloak = Keycloak(initOptions);
  const head = createHead();
  keycloak
    .init({})
    .then((auth) => {
      if (!auth) {
        keycloak.login({ scope: "offline_access" });
      } else {
        axios.interceptors.request.use((config) => {
          if (config.headers)
            config.headers.Authorization = `Bearer ${keycloak.token}`;
          return config;
        });

        app.use(head);
        app.use(router);
        app.use(pinia);
        app.mount("#app");
      }

      // Token Refresh
      setInterval(() => {
        keycloak
          .updateToken(100)
          .catch((error: string) => {
            console.error(`Failed to refresh token ${error}`);
          });
      }, 6000);
    })
};

function mountWithoutAuth() {
  const pinia = createPinia();
  const head = createHead();
  app.use(head);
  app.use(router);
  app.use(pinia);
  app.mount("#app");
};

if (navigator.onLine)
  authKeycloak();
else
  mountWithoutAuth();


window.addEventListener("online", () => {
  authKeycloak();
});

window.addEventListener("offline", () => {
  mountWithoutAuth();
});
// const head = createHead();
// const app = createApp(App)
//   .use(store)
//   .use(router)
//   .use(head)
//   .use(VueKeyCloak, {
//     config: config,
//     init: {
//       onLoad: "login-required",
//       checkLoginIframe: false,
//       silentCheckSsoRedirectUri: window.location.origin,
//     },
//     onReady: () => {
//       app.mount("#app");
//     },
//   });

// axios.interceptors.request.use(function (config) {
//   if (config.headers) {
//     config.headers.Authorization = "Bearer " + ;
//     return config;
//   }
// });
