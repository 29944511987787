import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/deliveries",
    name: "Deliveries",
    component: () =>
      import(
        /* webpackChunkName: "deliveries" */ "@/views/deliveries/Index.vue"
      ),
  },
  {
    path: "/deliveries/:deliveryId",
    name: "Delivery",
    component: () =>
      import(
        /* webpackChunkName: "delivery" */ "@/views/deliveries/Delivery.vue"
      ),
    redirect: { name: "DeliveryLines" },
    children: [
      {
        path: "details",
        name: "DeliveryDetails",
        component: () =>
          import(
            /* webpackChunkName: "delivery-details" */ "@/views/deliveries/DeliveryDetails.vue"
          ),
      },
      {
        path: "lines",
        name: "DeliveryLines",
        component: () =>
          import(
            /* webpackChunkName: "delivery-lines" */ "@/views/deliveries/DeliveryLines.vue"
          ),
      },
    ],
  },
  //     {
  //       path: "shipments",
  //       name: "ShipmentsOfDelivery",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "delivery-shipments" */ "@/views/deliveries/DeliveryShipments.vue"
  //         ),
  //     },
  //     {
  //       path: "lines/:lineId",
  //       name: "LineDetails",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "line-details" */ "@/views/lines/LineDetails.vue"
  //         ),
  //     },
  //   ],
  // },
  {
    path: "/shipments",
    name: "Shipments",
    component: () =>
      import(/* webpackChunkName: "shipments" */ "@/views/shipments/Index.vue"),
  },
  {
    path: "/shipments/:deliveryId/:shipmentId",
    name: "ShipmentDetails",
    component: () =>
      import(
        /* webpackChunkName: "shipment-details" */ "@/views/shipments/ShipmentDetails.vue"
      ),
  },
  // {
  //   path: "/signature",
  //   name: "Signature",
  //   component: () =>
  //     import(/* webpackChunkName: "signature" */ "@/views/Signature.vue"),
  // },
  {
    path: "/planner",
    name: "Planner",
    component: () =>
      import(/* webpackChunkName: "planner" */ "@/views/planner/Index.vue"),
  },
  {
    path: "/planner/:deliveryId/:shipmentId",
    name: "ShipmentToPlanDetails",
    component: () =>
      import(
        /* webpackChunkName: "shipment-details" */ "@/views/shipments/ShipmentDetails.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (
    !navigator.onLine &&
    to.name !== "Shipments" &&
    from.path !== "/shipments"
  ) {
    return { name: "Shipments" };
  }
});

export default router;
