<script setup lang="ts">
import { computed } from 'vue'

const revision = computed(() => import.meta.env.VITE_APP_COMMIT)
</script>

<template>
  <div class="flex items-center bg-neutral-200">
    <p class="px-3 text-xs text-left">
      Dernier commit : {{ revision }}
    </p>
  </div>
</template>

<style scoped>

</style>
