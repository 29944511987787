<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { CalendarIcon, InboxInIcon, TruckIcon } from '@heroicons/vue/outline'

const navigation = [
  {
    name: 'Préparation',
    to: { name: 'Deliveries', path: '/deliveries' },
    icon: InboxInIcon,
  },
  {
    name: 'Planification',
    to: { name: 'Planner', path: '/planner' },
    icon: CalendarIcon,
  },
  {
    name: 'Livraison',
    to: { name: 'Shipments', path: '/shipments' },
    icon: TruckIcon,
  },
]

const showMenu = ref(false)
const route = useRoute()
const isHome = computed(() => {
  return route.name === 'Home'
})
const selectedMenu = computed(() => {
  return navigation.find(menu => route.path.includes(menu.to.path))
})
function toggleMenu() {
  showMenu.value = !showMenu.value
}
</script>

<template>
  <header class="fixed z-[3000] top-0 w-full text-white bg-main-blue">
    <nav class="px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div
        class="flex items-center justify-around pt-3 pb-1 bg-main-blue lg:border-none"
      >
        <div class="flex justify-between w-full flex-nowrap">
          <router-link
            class="flex flex-col justify-center"
            :to="{ name: 'Home' }"
          >
            <img class="w-auto h-3 sm:h-10" src="/images/logo.png">
            <span class="block w-full text-sm text-left text-white sm:text-3xl">
              Logistics
            </span>
          </router-link>
          <div v-if="!isHome" class="flex-wrap hidden gap-5 ml-10 sm:flex">
            <router-link
              v-for="link in navigation"
              :key="link.name"
              :to="link.to"
              class="flex flex-col items-center gap-1 text-base font-medium"
              :class="[
                selectedMenu && link.name === selectedMenu.name
                  ? 'text-main-yellow'
                  : 'text-white',
              ]"
            >
              <component :is="link.icon" class="h-8" />
              <span>{{ link.name }}</span>
            </router-link>
          </div>
          <router-link
            v-if="selectedMenu"
            :to="selectedMenu.to"
            class="flex self-center gap-2 text-center sm:hidden"
          >
            {{ selectedMenu.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </header>
  <footer>
    <nav
      class="fixed bottom-0 z-[500] flex justify-around w-full gap-5 p-3 text-white transition-all bg-main-blue sm:hidden"
    >
      <router-link
        v-for="link in navigation"
        :key="link.name"
        :to="link.to"
        class="flex items-center gap-2 text-base font-medium"
        :class="[
          selectedMenu && link.name === selectedMenu.name
            ? 'text-main-yellow'
            : 'text-white',
        ]"
        @click="toggleMenu"
      >
        {{ link.name }}
      </router-link>
    </nav>
  </footer>
</template>
